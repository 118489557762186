@import '../../common/styles/constants';

$size: 30px;

.c-paginator {
    @include flex-parent(row, center, wrap);
    justify-content: center;
    margin: 15px auto;
    max-width: 720px;
    box-sizing: border-box;
    padding: 0 ($size + 20px);
    position: relative;

    .c-paginator-dots {
        display: block;
        width: $size;
        height: $size;
        margin: 4px;
        text-align: center;
        line-height: 32px;

        & + .c-paginator-dots {
            display: none;
        }
    }

    .c-paginator-btn {
        width: $size;
        height: $size;
        display: block;
        background-color: transparent;
        line-height: $size;
        text-align: center;
        border-radius: 1px;
        margin: 4px;
        color: #4e4f50;
        cursor: pointer;
        font-weight: bold;
        font-size: 14px;

        &.c-paginator-previous,
        &.c-paginator-next {
            margin-left: 15px;
            margin-right: 15px;
            line-height: 35px;
        }

        &:hover {
            background-color: #e6e6e6;
        }

        &.c-paginator-active {
            cursor: default;
            background-color: get-color(title);
            color: white;
        }
    }

    .c-paginator-menu {
        position: absolute;
        right: 0;
        top: 0;
        height: ($size + 8px);
        width: ($size + 8px);
        overflow: hidden;

        .c-paginator-btn {
            font-size: 20px;
            line-height: 31px;
            color: get-color('highlight');
        }

        select.c-paginator-options {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;

            &:hover + .c-paginator-btn {
                background-color: #e6e6e6;
            }
        }

        ul {
            display: block;
            list-style: none;
            padding: 0;
            margin: 0;

            > li {
                display: block;
                padding: 0;
                margin: 0;

                > a {
                    display: block;
                }
            }
        }
    }


}