@import '../../common/styles/constants';

$menuWidth: 320px;

.lop-menu {
    position: relative;
    width: 40px;
    height: 40px;
    overflow: hidden;
    background: transparent;
    margin: 0 15px;

    > button[type="button"] {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border: none;
        background-color: transparent;
        margin: 0;
        padding: 0;
        cursor: pointer;
    }

    > .lop-menu-list-blocker {
        display: none;
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #00000094;
        transition: 300ms;
        z-index: 100;

        &.lop-blocker-off {
            display: block;
            pointer-events: none;
            opacity: 0;
        }

        &.lop-blocker-on {
            display: block;
            pointer-events: default;
            opacity: 1;
        }
    }

    > .lop-menu-list-wrapper {
        display: none;
        flex-direction: column;
        flex-wrap: nowrap;
        position: fixed;
        top: 0;
        height: 100%;
        width: $menuWidth;
        background-color: get-color('background');
        box-shadow: 0 2px 10px 0 rgba(202, 202, 202, 0.25);
        z-index: 102;
        transition: 300ms ease-out;
        max-width: 100%;

        .lop-menu-header {
            flex-grow: 0;
            flex-shrink: 0;
        }
        .lop-menu-body {
            flex-grow: 1;
            flex-shrink: 0;
        }
        .lop-menu-footer {
            flex-grow: 0;
            flex-shrink: 0;
        }

        .lop-menu-list-content {
            padding: 30px;
            display: block;
        }

        > .lop-menu-list-head {
            position: relative;
            height: 97px;
            width: 100%;
            line-height: 97px;
            background-color: get-color('base');

            .lop-menu-logo {
                img {
                    max-width: 100%;
                    padding: 0 30px;
                    display: inline-block;
                }
            }
            
            button[type="button"].lop-menu-close-btn {
                width: 40px;
                height: 40px;
                display: block;
                margin: -20px 15px 0 0;
                padding: 0;
                border: none;
                position: relative;
                top: 50%;
                line-height: 40px;
                outline: none;
                background: transparent;
                
                > img {
                    position: relative;
                }
            }
        }

        ul.lop-menu-list {
            display: block;
            list-style: none;
            margin: 0;
            padding: 15px 0;

            > li {
                display: block;
                margin: 0;
                padding: 0;

                > a {
                    display: block;
                    padding: 15px 30px;
                    font-size: 16px;
                    color: get-color('main');

                    &.lop-menu-active {
                        border-left: solid 4px get-color('highlight');
                        font-weight: bolder;
                    }
                }
            }
        }

        &.lop-menu-closed {
            display: flex;
            left: -$menuWidth;
        }

        &.lop-menu-open {
            display: flex;
            left: 0;
        }
    }
}