@import '../../common/styles/constants';

.c-icon {
    display: inline-block;
    position: relative;

    > img.c-icon-normal {
        display: block;
        position: relative;
    }

    > img.c-icon-hover {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        @include transition();
    }

    &:hover {
        > img.c-icon-hover {
            opacity: 1;
        }
    }
}