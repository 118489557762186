@import "constants";

$input-class-name: "lop-input";
$label-class-name: "lop-label";


@mixin proportions($height) {
  @include border-radius($height / 2);
  height: $height;
  padding: 0 ($height / 2);
}

// input

#{get-element(input)} {
  @include proportions(41px);
  @include transition();
  border: 1px solid #D8D8D8;
  background-color: get-color(base);
  outline: none;
  color: #7C7C7C;
  font-family: get-font();
  font-size: 14px;
  letter-spacing: 0.09px;
  position: relative;
  overflow: hidden;

  &:focus {
    @include box-shadow(inset $color-highlight 0px 0px 1px 1px);
    border-color: $color-highlight;
  }

  &[disabled] {
    background-color: rgba(216, 216, 216, 0.3);
    cursor: not-allowed;
  }

  &[readonly],
  &.lop-input-readonly {
    background-color: #eaeaea !important;
  }
}

// label + input

#{get-element(label)} {
  display: block;
  position: relative;
	color: #808E97;
	font-family: $font-family;
	font-size: 14px;
	letter-spacing: 0.09px;
  line-height: 26px;
  font-weight: bold;
  padding: 0 20.5px;

  #{get-element(input)} {
    display: block;
    width: calc(100% + 41px);
    margin: 0 -20.5px;
  }

  &.lop-field-error {
    > #{get-element(input)} {
      border-color: #ff8484;
      background: rgba(255, 132, 132, 0.1);
    }
  }
}

// input-group + label + input

#{get-element(input-group)} {
  margin: 0 0 35px 0;

  #{get-element(input)} {
    width: 100%;
  }
}

// form-group

#{get-element(form-group)} {
  margin-bottom: 30px;
}