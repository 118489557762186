@import '../../common/styles/constants';

@keyframes show-prepend {
    from {
        opacity: 0;
        margin-top: -35px;
    }
    to {
        opacity: 1;
        margin-top: 10px;
    }
}

@keyframes show-append {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.paragraph-list {
    padding: 0;
    margin: 15px 0;
}

.paragraph-list-element {
    padding: 0;
    margin: 15px 0;
    background: get-color(base);
}

.paragraph-list-controls {
    display: block;
    margin: 10px 0;
    overflow: hidden;
    height: 35px;

    &:before,
    &:after {
        display: block;
        content: '';
        clear: both;
    }
}

.paragraph-list .paragraph-editable-animated .paragraph-list-controls {
    animation: show-prepend .5s;
}

.paragraph-list .paragraph-editable-animated .paragraph-list-append {
    animation: show-append 1.5s;

    .paragraph-list-append-right {
        text-align: right;
        height: 100%;
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-direction: column;

        .paragraph-list-character-counter {
            color: #ababab;
            font-family: Helvetica, -apple-system, sans-serif;
            font-size: 11px;

            &.error {
                color: #f77272;
            }
        }
    }
}

.paragraph-list-control {
    cursor: pointer;
    display: block;
    float: left;

    &.drag-handle {
        float: right;
    }

    &.level-info {
        line-height: 35px;
        padding: 0 10px;
        font-size: 12px;
        color: #878787;
    }
}

.paragraph-element {
    border-radius: 5px;
    background-color: get-color(base);
    box-shadow: 2px 3px 13px 0 rgba(223, 228, 236, 0.46);
    padding: 15px 35px;
    margin: 0;

    &:before,
    &:after {
        content: '';
        display: block;
        clear: both;
    }

    .level-content {
        margin: 0;

        .paragraph-content {
            margin: 20px 0;
            width: 100%;
            border: none;

            &[contenteditable="true"] {
                cursor: pointer;

                &:focus {
                    cursor: text;
                }
            }
        }

        .paragraph-content,
        .paragraph-content * {
            color: get-color(text) !important;
            font-family: get-font() !important;
            font-size: 15px !important;
            letter-spacing: 0.1px !important;
            line-height: 24px !important;
            white-space: normal !important;
            // background-color: transparent !important;
        }

        &.level-01 {
            margin-left: 0;

            .paragraph-content,
            .paragraph-content * {
                font-size: 17px !important;
                letter-spacing: 0.11px !important;
                line-height: 21px !important;
            }
        }

        &.level-02 {
            margin-left: 2.5%;
        }

        &.level-03 {
            margin-left: 5%;
        }

        &.level-04 {
            margin-left: 7.5%;
        }

        &.level-05 {
            margin-left: 10%;
        }

        &.level-06 {
            margin-left: 12.5%;
        }

        &.level-07 {
            margin-left: 15%;
        }

        &.level-08 {
            margin-left: 17.5%;
        }

        &.level-09 {
            margin-left: 20%;
        }

        &.level-10 {
            margin-left: 22.5%;
        }
    }

    &.paragraph-view-only {
        cursor: pointer;

        &.error .paragraph-content * {
            color: #f77272 !important;
        }

        &.shadow {
            opacity: .4;
            background-color: gainsboro;
            pointer-events: none;
        }
    }

    &.paragraph-editable {
        border: 2px solid get-color('highlight');
        padding: 13px 33px;

        &.error {
            border-color: #f77272;
            background: #fff9f9;
        }

        &.shadow {
            opacity: .4;
            background-color: gainsboro;
            pointer-events: none;
        }
    }
}