@keyframes fadeout-messagebar { 
    0% {
        opacity: 0;
    }

    5% {
        opacity: 1;
    }

    95% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes first-appear { 
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.c-message-bar {
    padding: 5px 0;
    color: #fff;
    font-weight: bold;
    text-align: left;
    font-size: 14px;
    position: fixed;
    width: 100%;
    z-index: 90;
    top: 97px;
    left: 0;
    // animation-delay: 4s;
    opacity: 1;
    // pointer-events: none;
    // animation: 8s fadeout-messagebar linear forwards;
    
    transition: 1000ms;

    &.c-message-bar-type-message {
        background: #4BA0F8;
    }

    &.c-message-bar-type-success {
        background: #62bd0f;
    }

    &.c-message-bar-type-danger {
        background: #ffa800;
    }

    &.c-message-bar-type-error {
        background: #de3d21;
    }

    &:not(.c-message-bar-empty) {
        animation: 300ms first-appear linear;
    }

    &.c-message-bar-empty {
        pointer-events: none;
        opacity: 0;
    }
}