@import '../../common/styles/constants.scss';

.c-tags {
    display: block;
	border-radius: 7px;
    background-color: #F5F6F8;
    padding: 15px;
    margin: 10px 0;
    
    .c-tags-title {
        color: get-color(title);
        font-family: Helvetica;
        font-size: 15px;
        letter-spacing: 0.1px;
        line-height: 26px;
        font-weight: bold;
        padding: 0 20.5px;
    }

    input.lop-input {
        background-color: transparent;
    }
}

ul.c-tag-list {
    display: inline;
    padding: 0;
    margin: 0;
    list-style: none;

    > li.c-tag-list-item {
        display: inline;
        margin-left: 4px;

        &:after {
            content: ',';
        }


        &:last-child {
            &:after {
                content: '';
            }
        }
    }
}

.paragraph-content-extra {
    color: black;
    opacity: .4;
    font-size: 12px;
    margin: 12px 0;
    
    > span {
        display: inline-block;

        &.paragraph-content-extra-level {
            width: 100%;
            max-width: 90px;
        }
    }
}

.c-tags-buttonpad {
    text-align: right;
    margin: 15px -7px 0;

    > button.c-tags-btn {
        display: inline-block;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        color: #005A9C;
        box-sizing: border-box;
        padding: 0 15px;
        border: solid 1px #005A9C;
        border-radius: 12px;
        margin: 0 7px;
        background-color: transparent;

        &:disabled {
            border-color: gray;
            color: gray;
            cursor: not-allowed;
        }
    }
}
