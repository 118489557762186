@import "constants";

html {
  height: 100%;
  position: relative;
}

body {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
  color: $color-text;
  background-color: #F9FAFB;
  position: relative;
  min-height: 100%;
}

p {
  color: $color-text;
  font-family: $font-family;
  font-size: 15px;
  letter-spacing: 0.1px;
  line-height: 24px;
}

h1, h2 {
  font-family: $font-family;
  color: $color-title;
}

h1 {
  font-size: 23px;
  letter-spacing: 0.15px;
  line-height: 26px;
}

h2 {
  font-size: 20px;
  letter-spacing: 0.13px;
  line-height: 29px;
}

select {
  outline : none;
  overflow : hidden;
  text-indent : 0.01px;
  text-overflow : '';
  -webkit-appearance: none;
     -moz-appearance: none;
      -ms-appearance: none;
       -o-appearance: none;
          appearance: none;

  padding-right: 40px !important;
  background-image: url(../../assets/icons/flecha@3x.png);
  background-repeat: no-repeat;
  background-size: 11px 7px;
  background-position: calc(100% - 15px) center;

  &::-ms-expand {
    display: none;
  }

  &[multiple] {
    background-image: none;
  }
}

hr {
  height: 1px;
  background: #E3E3E3;
  border: none;
  margin: 30px 0;
}