@import '../../common/styles/constants';

.lop-section {
    display: block;
    width: 100%;
    margin: 20px 0;

    .container {
        &:before, &:after {
            display: none;
        }
    }

    .lop-section-box {
        display: block;
    }

    .lop-section-inner {
        display: block;
        position: relative;
        padding: 0;
    }

    &.lop-section-white-box {
        .lop-section-box {
            background-color: white;
            border: solid 1px #f1f1f1;

            &:before, &:after {
                display: table;
                content: " ";
            }
        }

        .lop-section-inner {
            padding: 0 30px;
            margin: 30px 0;
        }
    }
    &.lop-section-white {
        background-color: white;
        border-top: solid 1px #f1f1f1;
        border-bottom: solid 1px #f1f1f1;

        .container {
            &:before, &:after {
                display: table;
                content: " ";
            }
        }

        .lop-section-inner {
            margin: 30px 0;
        }
    }
}