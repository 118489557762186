.btn-save-editions {
    float: right;
    min-width: 130px;
}

.lop-file {
    input[type="file"] {
        display: none;
    }
}

.lop-duplicated-paragraphs-info {
    background: #ffabab;
    padding: 1px 30px;
    border-radius: 4px;

    p {
        margin: 10px 0;
        font-size: 14px;

        strong {
            cursor: pointer;
        }
    }
}