.c-authentication-form {
	max-width: 475px;
	width: 100%;
	border-radius: 7px;
	background-color: #F9FAFB;
	box-shadow: 7px 11px 23px 0 rgba(0,0,0,0.61);
	padding: 45px;
	margin: 75px 0;

	.c-authentication-form-title {
		margin: 30px 20px;
		font-size: 28px;
	}

	.c-authentication-form-text {
		margin: 30px 20px;
	}

	.c-authentication-form-help {
		display: block;
		text-align: right;
	}
}