@import '../../common/styles/constants';

.c-multitag {
    display: block;
    position: relative;

    .c-multitag-options {
        display: block;
        position: absolute;
        left: 0;
        top: 100%;
        margin: 0;
        background: white;
        width: 100%;
        list-style: none;
        padding: 0;
        border-radius: 3px;
        box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px;
        z-index: 100;
        overflow-x: hidden;
        overflow-y: auto;

        > li {
            display: block;
            padding: 0;
            font-family: get-font();
            font-size: 12px;
            cursor: pointer;

            span.c-multitag-option {
                display: block;
                padding: 10px 26.5px;

                &.c-multitag-focused {
                    background: rgba(202, 202, 202, 0.45);
                }

                &.c-multitag-selected {
                    background: rgba(202, 202, 202, 0.25);
                    color: get-color('main');
                }

                &.c-multitag-disabled {
                    background: rgba(202, 202, 202, 0.25);
                    color: #BDBDBD;
                }
            }
        }

        // &:hover {
        //     > li {
        //         &.c-multitag-focused {
        //             background: transparent;
        //         }
        
        //         &:hover {
        //             background: rgba(202, 202, 202, 0.25);
        //         }
        //     }
        // }
    }

    .c-multitag-sub-options {
        display: block;
        position: relative;
        margin: 0;
        list-style: none;
        padding: 0;
    }
}

.c-multitag-field {
    @include flex-parent(row, center, wrap);
    position: relative;
    min-height: 41px;
    padding: 0 15.5px;

    .c-multitag-selections {
        @include flex-child(0, 0);
        margin: 0 -5px;
        list-style-type: none;
        z-index: 1;
        padding: 0;
        max-width: 100%;

        &:before,
        &:after {
            content: '';
            display: block;
            clear: both;
        }

        > li {
            display: block;
            float: left;
            padding: 0;
            font-size: 14px;
            letter-spacing: 0.09px;
            font-family: get-font();
            color: #7C7C7C;
            margin: 8.5px 5px;

            > span {
                color: white;
                display: inline-block;
                padding: 4px 6px;
                border-radius: 12px;
                cursor: pointer;

                .c-multitag-icon {
                    float: right;
                    display: block;
                    font-weight: bold;
                    margin: -1px 0 0 5px;
                    opacity: .5;
                    @include transition(200ms);
                }

                &:hover {
                    .c-multitag-icon {
                        opacity: 1;
                    }
                }

                &.c-multitag-id-value {
                    background: get-color(main);
                }

                &.c-multitag-label-value {
                    background: get-color('highlight');

                    i.c-multitag-flag {
                        font-size: 10px;
                        opacity: .5;
                    }
                }
            }

            &.c-multitag-deletable {
                > span {
                    background: get-color('highlight');
                }
            }
        }
    }

    .c-multitag-input {
        @include flex-child();
        border: none;
        z-index: 1;
        font-size: 14px;
        letter-spacing: 0.09px;
        font-family: get-font();
        color: #7C7C7C;
        min-height: 41px;
        margin: 0 5px;
        padding: 0;
        background-color: transparent;
        outline: none;

    }

    .c-multitag-appearance {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background: transparent;
    }
}