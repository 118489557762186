@import '../../common/styles/constants';

.lop-agreement-list-item {
    @include flex-parent(row, top, no-wrap);
    @include border-radius(5px);
    background-color: $color-base;
    overflow: hidden;
    padding: 7.5px 15px;

    > .lop-agreement-list-item-detail {
        @include flex-child();
        padding: 7.5px;
        color: $color-title;
        font-family: $font-family;
        font-size: 13px;
        letter-spacing: 0.08px;
        line-height: 17px;

        > a {
            color: $color-title;
        }

        &.lop-agreement-title {
            line-height: 18px;
            font-weight: bold;
        }

        &.lop-agreement-actions {
            text-align: center;
            white-space: nowrap;

            > a.lop-action {
                display: inline-block;
                width: 35px;
                height: 35px;
                position: relative;
                overflow: hidden;
                margin: 0 15px 0 0;
                cursor: pointer;

                > img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    border: none;
                    padding: 0;
                    left: 0;
                    top: 0;

                    &.lop-hover-pic {
                        @include transition();
                        position: absolute;
                        opacity: 0;
                        z-index: 1;
                    }
                }

                &:hover {
                    img.lop-hover-pic {
                        opacity: 1;
                    }
                }
            }
        }

        &.lop-agreement-updated {
            .lop-date,
            .lop-time {
                display: block;
            }
        }
    }

    &:hover {
        @include box-shadow(2px 3px 13px 0 rgba(223, 228, 236, 1));
    }
}

.lop-agreements {
    margin: 20px 0;

    ul.lop-agreement-list-header {
        @include flex-parent(row, baseline, nowrap);
        border-top: 1px solid #E3E3E3;
        list-style: none;
        margin: 0;
        padding: 15px 15px 0 15px;

        > li {
            @include flex-child();
            min-height: 15px;
            color: #808E97;
            font-family: $font-family;
            font-size: 13px;
            letter-spacing: 0.08px;
            line-height: 26px;
            text-transform: uppercase;
            font-weight: 600;
            padding: 0 7.5px;

            &.lop-clickable {
                cursor: pointer;

                &:hover {
                    background: #eeeeef;
                    border-radius: 4px;
                }
            }

            .lop-sort-up > svg {
                margin: 0px 0 -4px 0;
                display: inline-block;
            }
        }
    }

    ul.lop-agreement-list {
        list-style: none;
        margin: 15px 0;
        padding: 0 0 20px 0;
        border-bottom: 1px solid #E3E3E3;

        > li {
            display: block;
            padding: 0;
            margin: 0 0 10px 0;
        }
    }
}



.lop-agreement-title {
    width: 40%;
}

.lop-agreement-country,
.lop-agreement-date,
.lop-agreement-author,
.lop-agreement-published,
.lop-agreement-updated,
.lop-agreement-actions {
    width: 10%;
}

.lop-agreement-actions {
    margin-left: 15px;
}