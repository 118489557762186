@import '../../common/styles/constants';

@keyframes signee-highlight {
    from {
        background-color: #fffbde;
    }
    to {
        background-color: transparent;
    }
}

.lop-signee {
    .lop-signee-animated {
        max-height: 1000px;
        overflow: visible;
        padding-top: 30px;
        padding-bottom: 30PX;
        margin-top: -30px;
        margin-bottom: -30px;

        &.lop-signee-deleting {
            @include transition(300ms linear);
            pointer-events: none;
            overflow: hidden;
            opacity: 0;
            z-index: -100;
            max-height: 0;
        }

        &.lop-signee-highlight {
            animation: signee-highlight 4s linear;
        }
    }
}