$colors: (
  main: #005A9C,
  highlight: #4BA0F8,
  background: #F9FAFB,
  title: #003161,
  text: #4E4F50,
  text-light: #727679,
  base: #ffffff
);

$elements: (
  input: '.lop-input',
  label: '.lop-label',
  select: 'select.lop-input',
  input-group: '.lop-field-group',
  form-group: '.lop-form-group',
  section-title: '.lop-section-title',
  text-area: '.lop-text-area'
);

$stylers: (
  small: '.lop-small',
  large: '.lop-large'
);

$fonts: (
  default: (Helvetica, -apple-system, sans-serif)
);

$config: (
  animations-velocity: 200ms
);

@function get-color($color-name, $color-value: false) {
  @if $color-value {
    $colors: map-merge(($color-name: $color-value), $colors);
  }
  @return map-get($map: $colors, $key: $color-name);
}

@function get-font($font-name: default) {
  @return map-get($map: $fonts, $key: $font-name);
}

@function get-element($element-name: false) {
  @if $element-name {
    @return map-get($map: $elements, $key: $element-name);
  }
  @return '*';
}

@function get-config($config-name) {
  @return map-get($map: $config, $key: $config-name);
}

$color-main: #005A9C;
$color-highlight: #4BA0F8;
$color-background: #F9FAFB;
$color-title: #003161;
$color-text: #4E4F50;
$color-text-light: #727679;
$color-base: #ffffff;

$font-family: Helvetica, -apple-system, sans-serif;

$animations-velocity: 200ms;

$class-name-small: 'lop-small';
$class-name-large: 'lop-large';

$media-query-sm: 'media screen and (max-width: 768px)';

@mixin box-shadow($value...) {
  -webkit-box-shadow: $value;
  -moz-box-shadow: $value;
  box-shadow: $value;
}

@mixin transition($value: $animations-velocity) {
  -webkit-transition: $value;
  -moz-transition: $value;
  -ms-transition: $value;
  -o-transition: $value;
  transition: $value;
}

@mixin border-radius($value: 4) {
  -webkit-border-radius: $value;
  -moz-border-radius: $value;
  border-radius: $value;
}

@mixin box-sizing($value: border-box) {
  -webkit-box-sizing: $value;
  -moz-box-sizing: $value;
  box-sizing: $value;
}

@mixin flex-direction($value: row) {
  -webkit-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}

@mixin display-flex() {
  display: -webkit-flex;
  display: flex;
}

@mixin flex-shrink($value: 1) {
  -webkit-flex-shrink: $value;
  flex-shrink: $value;
}

@mixin flex-grow($value: 1) {
  -webkit-flex-grow: $value;
  flex-grow: $value;
}

@mixin align-items($value: baseline) {
  -webkit-align-items: $value;
  align-items: $value;
}

@mixin flex-wrap($value: wrap) {
  -webkit-flex-wrap: $value;
  -ms-flex-wrap: $value;
  flex-wrap: $value;
}

@mixin flex-parent($flex-direction: row, $align-items: baseline, $flex-wrap: wrap) {
  @include display-flex();
  @include flex-direction($flex-direction);
  @include align-items($align-items);
  @include flex-wrap($flex-wrap);
}

@mixin flex-child($flex-shrink: 1, $flex-grow: 1) {
  @include flex-shrink($flex-shrink);
  @include flex-grow($flex-grow);
}

@mixin media-query-xs {
  @content;
}

@mixin media-query-sm {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin media-query-md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin media-query-lg {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin media-query-xl {
  @media (min-width: 1200px) {
    @content;
  }
}