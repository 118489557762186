@import "constants";

#{get-element(section-title)} {
    color: get-color(title);
    font-family: get-font();
    font-size: 16px;
    letter-spacing: 0.1px;
    line-height: 26px;
    min-height: 26px;
    font-weight: bold;
}

.lop-link {
	color: get-color('highlight');
	font-family: get-font();
	font-size: 13px;
	letter-spacing: 0.08px;
    line-height: 16px;
    cursor: pointer;
}