@import "constants";

$border-width: 1px;

@mixin proportions($min-height, $line-height, $border-width: $border-width) {
  @include border-radius($min-height / 2);
  min-height: $min-height;
  line-height: $line-height;
  padding: ((($min-height - $line-height) / 2) - $border-width) ($min-height / 2);
}

@mixin solid-color($color) {
  background-color: $color;
  border-color: $color;
}

.lop-btn {
  @include proportions(44px, 16px);
  @include box-shadow(2px 3px 13px 0 rgba(60, 132, 203, 0.45));
  @include transition();
  @include solid-color($color-highlight);
  border: solid $border-width $color-highlight;
  font-family: $font-family;
  color: $color-base;
  font-size: 15px;
  letter-spacing: 0.1px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  display: inline-block;

  &:hover,
  &:focus {
    @include solid-color($color-main);
    color: white;
    text-decoration: none;
  }

  // Children

  > img.lop-btn-icon {
    display: inline-block;
    border: none;
    margin: 1px 10px 0 0;
    padding: 0;
    width: 11px;
    height: 11px;
  }

  // Default states

  &[disabled] {
    @include box-shadow(none);
    background-color: get-color(text-light);
    cursor: not-allowed;
  }

  // Styles

  &.lop-btn-dark {
    @include box-shadow(2px 3px 13px 0 rgba(0, 90, 156, 0.45));
    @include solid-color($color-main);

    &:hover,
    &focus {
      @include solid-color(#003E6B);
    }

    &[disabled] {
      @include box-shadow(none);
      background-color: get-color(text-light);
      border-color: get-color(text-light);
    }
  }

  &.lop-btn-outline {
    @include box-shadow(none);
    background-color: transparent;
    border-color: $color-main;
    color: $color-main;

    &:hover,
    &:focus {
      @include solid-color($color-main);
      color: $color-base;
    }
  }


  // Sizes

  &.#{$class-name-large} {
    @include proportions(48px, 16px);
  }

  &.#{$class-name-small} {
    @include proportions(33px, 17px);
    font-size: 13px;
    letter-spacing: 0.08px;
  }
}