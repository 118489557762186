.lop-login-page {
    background-image: url('./background.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    min-height: 100%;
    left: 0;
    top: 0;

    .lop-login-header {
        padding: 30px 0;

        .c-icon {
            margin: 15px 30px;
        }
    }
}