@import '../../common/styles/constants';

.lop-search-form {
  @include border-radius(25.5px);
  @include box-shadow(4px 9px 12px 0 rgba(0, 0, 0, 0.06));
  @include box-sizing(border-box);
  border: 1px solid #E5E6E9;
  max-width: 698px;
  width: 100%;
  height: 51px;
  margin: 0 auto;
  padding: 2px;
  overflow: visible;

  .lop-search-form-input {
    height: 41px;
    margin: 2px;
    float: left;
    border: none;
    outline: none;
    padding: 0 15px;
    width: 100%;
    display: block;
    background-color: get-color(base);
    outline: none;
    color: #7C7C7C;
    font-family: get-font();
    font-size: 14px;
    letter-spacing: 0.09px;

    &[type="text"] {
      @include border-radius(20.5px 0 0 20.5px);
    }
  }

  button {
    float: right;
    height: 41px;
    margin: 2px;

    &[type="submit"] {
      @include box-shadow(2px 3px 10px 0 rgba(69, 109, 139, 0.42));
      width: 41px;
      border-radius: 50%;
      overflow: hidden;
      border: none;
      background-color: $color-main;
      padding: 0;
      cursor: pointer;
      outline: none;

      > img {
        display: block;
        width: 90%;
        height: 90%;
        margin: 4px;
      }

      &[disabled] {
        background-color: get-color(text-light);
        opacity: .5;
      }
    }
  }

  .lop-search-form-vertical-rule {
    @include box-sizing(border-box);
    display: block;
    float: left;
    height: 37px;
    width: 1px;
    border-right: 1px solid #D5D5D5;
    margin: 5px 2px;
  }

  .search-form-items {
    @include flex-parent(row, center, nowrap);
    width: 100%;
    height: 100%;

    .lop-search-form-item {
      @include flex-child(0, 0);
    }

    .lop-search-form-title {
      @include flex-child(0, 1);
    }

    .lop-search-form-country {
      flex-grow: 1;

      > .lop-search-form-input {
        width: 200px;
      }

      .lop-input {
        border: none;
      }
      // @include flex-child(0, 1);
    }

    .lop-search-form-date {
      > .lop-search-form-input {
        background-image: url(./icon-calendar@3x.png);
        background-position: 8px center;
        background-repeat: no-repeat;
        background-size: 17px;
        padding: 0 0 0 34px;
        width: 173px;
      }
    }
  }
}