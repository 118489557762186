@import "constants";

.dropdown {
    > button.dropdown-toggle.btn.btn-primary {
        border: none;
    }

    .dropdown-menu {
        @include box-shadow(0 2px 10px 0 rgba(202, 202, 202, 0.25));
        border: 1px solid #E3E3E3;

        .dropdown-item {
            color: #ABABAB;
            font-family: Helvetica;
            font-size: 14px;
            letter-spacing: 0.09px;
            line-height: 26px;
            display: block;
            padding: 10px 25px;
            white-space: normal;
        }
    }
}