@import '../../../common/styles/constants';

.lop-checkbox {
    display: inline-block;
    position: relative;
    margin: 4px 4px 4px 0;

    .lop-checkbox-box {
        @include transition();
        background-color: get-color(base);
        width: 14px;
        height: 14px;
        float: left;
        margin: 2px 6px 5px 0;
        border: 1px solid #cacaca;
        border-radius: 2px;

        > .lop-checkbox-mark {
            width: 8px;
            margin: 0;
            padding: 0;
            position: absolute;
            left: 3px;
            top: 6px;
        }
    }

    .lop-checkbox-content {
        display: block;
        text-align: left;
        color: get-color(text-light);
        font-family: get-font();
        font-size: 13px;
        letter-spacing: 0.08px;
        line-height: 17px;
        margin: 0 0 0 20.5px;
    }

    input[type="checkbox"].lop-checkbox-core {
        position: absolute;
        display: block;
        margin: 2px 6px 5px 0;
        padding: 0;
        width: 14px;
        height: 14px;
        opacity: 0;

        &:checked {
            + .lop-checkbox-box {
                background-color: get-color('highlight');
                border-color: get-color('highlight');
            }
        }
    }
}