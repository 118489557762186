@import '../../common/styles/constants';

$footer-height: 60px;
$footer-line-height: 20px;

.lop-footer-container {
    height: $footer-height;
    position: static;
    width: 100%;

    > footer.lop-footer {
        height: $footer-height;
        width: 100%;
        position: absolute;
        bottom: 0;
        background-color: $color-base;
        color: $color-title;
        font-family: $font-family;
        font-size: 13px;
        letter-spacing: 0.08px;
        padding: (($footer-height - $footer-line-height) / 2) 5px;
        line-height: $footer-line-height;

        .lop-footer-logo-area {
            padding: 0;
            
            > img {
                height: 31px;
                margin: 0 15px;
            }
        }
    }
}