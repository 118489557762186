.lop-control-bar {
    margin: -15px 0 0 0;
    height: 74px;
    background-color: #F9FAFB;

    .lop-control-bar-fixed-area {
        position: fixed;
        width: 100%;
        z-index: 90;
        border-bottom: 1px solid #E3E3E3;
        padding: 15px 0;
        background-color: #F9FAFB;
    }
}

button.lop-submit {
    float: right;
}

.lop-edit-tags-title {
    height: 44px;
    line-height: 48px;
    margin: 0;
}

.lop-edit-tags-buttons {
    float: right;
    margin: 0 -15px;

    > button {
        float: left;
        margin: 0 15px;
    }
}

li.lop-sortable-tag {
    display: block;
    padding: 0;
    margin: 10px 0;
    position: relative;
    outline: none;

    .editable-tag-guides {
        position: absolute;
        left: 15px;
        top: 41px;
        z-index: -1;
        width: 100%;
        padding: 0 15px 0 0;

        > span {
            display: block;
            position: relative;
            background: red;

            &:first-child {
                width: 100%;
                height: 1px;
            }

            &:last-child {
                position: absolute;
                width: 1px;
                height: 46px;
                top: -46px;
            }
        }
    }

    .editable-tag {
        display: block;
        padding: 0;
        margin: 0;
        position: relative;
        outline: none;
        border-radius: 5px;
        background: #ffffff;
        border: solid 1px #ffffff;

        &:before,
        &:after {
            content: '';
            display: block;
            clear: 'both';
        }

        .lop-sortable-tag-handle {
            float: left;
            padding: 28px 14.5px;
            cursor: move;
        }

        .lop-editable-tag-actions {
            float: right;
            padding: 23px 14.5px;

            button {
                font-size: 20px;
                border: none;
                background: transparent;
                height: 30px;
                position: relative;
                width: 30px;
                line-height: 30px;
                text-align: center;
                font-weight: bold;
                color: #005A9C;
            }
        }

        .editable-tag-text {
            padding: 17px 0;
            overflow: hidden;

            > input[type="text"].lop-input {
                border: none;
                width: 100%;
                display: block;
            }
        }

        &.lop-focused {
            box-shadow: 2px 3px 13px 0 #dfe4ec;
        }

        &.lop-changed {
            color: #4BA0F8;

            input {
                color: #4BA0F8;
            }
        }

        &.lop-new {
            color: #5db129;
            background-color: #f6fbf2;
            border-color: rgb(93, 177, 41);

            input {
                color: #5db129;
                background-color: transparent;

                &:focus {
                    background-color: white;
                }
            }
        }

        &.lop-removed {  
            opacity: .4;
            box-shadow: none !important;

            .editable-tag-text,
            .lop-sortable-tag-handle,
            .lop-editable-tag-actions {
                pointer-events: none;
            }

            .lop-sortable-tag-handle,
            .lop-editable-tag-actions {
                visibility: hidden;
            }
        }
    }
}

ul.lop-sortable-tags {
    display: block;
    padding: 0;
    margin: 0;
    position: relative;
    list-style: none;
    outline: none;

    li.lop-sortable-tag {
        .editable-tag {
            box-shadow: none;
        }
    }
}