@import '../../common/styles/constants';

$top-bar-small-height: 91px;
$top-bar-height: 97px;

.lop-top-bar-area {
  display: block;
  width: 100%;
  margin: 0 0 15px 0;
  background-color: $color-base;

  .lop-top-bar {
    @include flex-parent(row, center, nowrap);
    @include box-shadow(0 2px 10px 0 rgba(202,202,202,0.25));
    position: relative;
    width: 100%;
    overflow: visible;
    padding: 0 15px;
    min-width: 640px;
    background-color: $color-base;
    z-index: 100;

    .lop-user-dropdown-btn {
      > span.lop-user-name-btn {
        margin: 0 10px;
        color: #003161;
        font-family: Helvetica;
        font-size: 14px;
        letter-spacing: 0.09px;
        font-weight: bold;
      }

      > span.c-icon {
        &:first-child {
          float: left;
        }
      }
    }

    .lop-top-bar-left,
    .lop-top-bar-center,
    .lop-top-bar-right {
      @include box-sizing(border-box);
      @include flex-parent(row, center);
      height: $top-bar-small-height;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .lop-top-bar-left,
    .lop-top-bar-right {
      @include flex-child(0, 0);
    }

    .lop-top-bar-center {
      @include flex-child(0, 1);
      padding-left: 20px;
      padding-right: 20px;
    }

    .lop-top-bar-logo {
      display: block;
      height: 30px;
      width: auto;
      margin: 0 15px;
    }

    .lop-logo-list {
      display: inline-block;
      padding: 0 15px;

      > img {
        display: block;
        margin: 0 15px;
        height: 30px;
        float: left;
      }
    }
  }

  @include media-query-md {
    height: $top-bar-height;

    .lop-top-bar {
      position: fixed;

      .lop-top-bar-left,
      .lop-top-bar-center,
      .lop-top-bar-right {
        height: $top-bar-height;
      }

      .lop-top-bar-logo {
        // height: 33px;
      }

      .lop-logo-list {
        > img {
          // margin-right: 20px;
          // height: 33px;
        }
      }
    }
  }

  @include media-query-lg {
    .lop-top-bar {
      .lop-top-bar-center {
        // padding: 0 40px;
      }

      .lop-logo-list {
        > img {
          // margin-right: 40px;
        }
      }
    }
  }
}