@import '../../common/styles/constants';

.text-editor {
    min-height: 20px;

    div {
        margin: 0 0 10px 0;
    }

    &.empty {
        background: rgb(245, 246, 248);
        border-bottom: solid 1px #d8d8d8;
    }
}

ul.fn-context-options {
    position: absolute;
    z-index: 99;
    list-style: none;
    padding: 0;
    border-radius: 5px;
    border: 1px solid #E9E9E9;
    background-color: #FFFFFF;
    @include box-shadow(-2px 7px 15px -2px rgba(0,0,0,0.15));
    box-sizing: border-box;
    margin: 0;
    overflow: hidden;

    > li {
        color: #4BA0F8;
        font-family: Helvetica;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0.1px;
        line-height: 19px;
        padding: 11px 16px;
        cursor: pointer;
        @include transition(200ms);

        &:hover {
            background-color: #4BA0F8;
            color: #ffffff;
        }
    }
}