@import '../../../common/styles/constants';

.lop-radio {
    display: inline-block;
    position: relative;
    margin: 4px 4px 4px 0;

    .lop-radio-ellipsis {
        @include transition();
        @include border-radius(50%);
        background-color: get-color(base);
        width: 14px;
        height: 14px;
        float: left;
        margin: 2px 6px 5px 0;
        border: 1px solid #cacaca;

        > .lop-radio-mark {
            @include border-radius(50%);
            width: 6px;
            height: 6px;
            margin: 0;
            padding: 0;
            position: absolute;
            left: 4px;
            top: 6px;
            background-color: get-color(base);
        }
    }

    .lop-radio-content {
        display: block;
        text-align: left;
        color: get-color(text-light);
        font-family: get-font();
        font-size: 13px;
        letter-spacing: 0.08px;
        line-height: 17px;
        margin: 0 0 0 20.5px;
    }

    input[type="radio"].lop-radio-core {
        position: absolute;
        display: block;
        margin: 2px 6px 5px 0;
        padding: 0;
        width: 14px;
        height: 14px;
        opacity: 0;

        &:checked {
            + .lop-radio-ellipsis {
                background-color: get-color('highlight');
                border-color: get-color('highlight');
            }
        }
    }
}