@import '../../common/styles/constants';

.lop-button-plus {
    border: none;
    display: inline-block;
    background: transparent;
    cursor: pointer;
    text-decoration: none;
    outline: none;

    .lop-button-icon {
        width: 32px;
        height: 32px;
        background: get-color('highlight');
        display: block;
        float: left;
        border-radius: 50%;
        overflow: hidden;
        line-height: 32px;
        text-align: center;

        > img {
            width: 10px;
            height: 10px;
            display: inline-block;
        }
    }

    .lop-button-content {
        display: block;
        color: get-color('highlight');
        font-family: get-font();
        font-size: 14px;
        letter-spacing: 0.09px;
        line-height: 17px;
        font-weight: bold;
        text-align: left;
        overflow: hidden;
        padding: 7px 0 0 10px;
    }

    &:hover {
        text-decoration: underline;
    }

    &.lop-button-disabled {
        pointer-events: none;
        
        .lop-button-icon {
            background-color: get-color(text-light);
        }
        
        .lop-button-content {
            color: get-color(text-light);
        }
    }
}